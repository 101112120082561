export default {
  namespaced: true,
  state: {
    id: '',
    name: '',
    companyId: '',
    orgId: '',
  },
  mutations: {
    updateId(state, id) {
      state.id = id;
    },
    updateName(state, name) {
      state.name = name;
    },
    updateCompanyId(state, companyId) {
      state.companyId = companyId;
    },
    updateOrgId(state, orgId) {
      state.orgId = orgId;
    },
  },
};
